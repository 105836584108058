import classes from "./LoginScreen.module.css";

const LoginScreen = (props) => {
  return (
    <div className={classes.loginScreen}>
      <div className={classes.firstColumn}>
        <div>Logo</div>
      </div>
      <div className={classes.secondColumn}>
        <div className={classes.logoDiv}>Logo</div>
        {props.children}
        </div>
    </div>
  );
};
export default LoginScreen;
