import classes from "./MyLessons.module.css";
import { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import NotificationOrange from "../../UI/NotificationOrange";
import axios from "axios";
import Loading from "../../UI/Loading";
import { minWidth } from "@mui/system";

const MyLessons = (props) => {
  const [errorInMouse, setErrorInMouse] = useState(false);
  const [myLessons, setMyLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterLector, setFilterLector] = useState(0);
  const [filterCategory, setFilterCategory] = useState(0);
  const [filterSwimmer, setFilterSwimmer] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [valid, setValid] = useState(true);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const getMyLessons = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        `https://login.mtmacademy.cz/api/v1/my-lessons?swimmer_id=${filterSwimmer}&lector_id=${filterLector}&category_id=${filterCategory}&month_id=${filterMonth}`,
        { headers }
      );
      setMyLessons(response.data.data);
      // console.log(response.data.data);
      if (response.data.data.length === 0) {
        setValid(false);
      } else {
        setValid(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getMyLessons();
    // console.log(filterMonth);
  }, [filterCategory, filterSwimmer, filterMonth]);
  const cancelLessonLoadingTrueHandler = () => {
    setLoading(true);
  };
  const cancelLessonLoadingFalseHandler = () => {
    setLoading(false);
  };

  const [selectedButton, setSelectedButton] = useState("Všichni plavci");
  const [openNotificationOrange, setOpenNotificationOrange] = useState(false);
  const [openMonthModal, setOpenMonthModal] = useState(false);
  const [filterMonthValue, setFilterMonthValue] = useState("");
  const [openLectorModal, setOpenLectorModal] = useState(false);
  const [filterLectorValue, setFilterLectorValue] = useState("");
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [filterCategoryValue, setFilterCategoryValue] = useState("");

  const deleteHandler = (object) => {
    function closeNotificationOrange() {
      setOpenNotificationOrange(false);
    }
    setOpenNotificationOrange(true);
    setTimeout(closeNotificationOrange, 2000);
  };
  function getDayName(dateStr, locale) {
    var dateObject = dateStr.split("/");
    const day = dateObject.splice(0, 1)[0];

    const month = dateObject.splice(0, 1)[0];
    const year = dateObject.splice(0, 1)[0];
    const newDate = `${month}/${day}/${year}`;
    const validDate = new Date(newDate);

    return validDate.toLocaleDateString(locale, { weekday: "long" });
  }
  const submitHandler = async (paymentURL) => {
    handlePay({ gwUrl: paymentURL });
    // console.log(paymentURL);
  };
  const handlePay = (createResult) => {
    try {
      window._gopay.checkout(
        { gatewayUrl: createResult.gwUrl, inline: true },
        async (checkoutResult) => {
          console.log(
            `Stav platby ${checkoutResult.id}: ${checkoutResult.state}`
          );
          if (checkoutResult && checkoutResult.state === "PAID") {
            getMyLessons();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="contentContainer">
      <h1 className="h1">Mé lekce</h1>
      <div className={classes.tabHeader}>
        <div className={classes.tabsDiv}>
          <div
            onClick={() => {
              setSelectedButton("Všichni plavci");
              setFilterLectorValue("");
              setFilterCategoryValue("");
              setFilterSwimmer("");
            }}
            className={
              selectedButton === "Všichni plavci" ? classes.tab : classes.tabDis
            }
          >
            Všichni plavci
          </div>
          {props.swimmers.map((object) => {
            return (
              <div
                className={
                  selectedButton === object.id ? classes.tab : classes.tabDis
                }
                onClick={() => {
                  setSelectedButton(object.id);
                  setFilterSwimmer(object.id);
                }}
                key={object.id}
              >
                {object.first_name}
              </div>
            );
          })}
        </div>
        <div className={classes.headerButton}>
          <Link className={classes.link} to="/dashboard-lessons">
            <button>Přihlásit se na lekci</button>
          </Link>
        </div>
      </div>
      <Divider style={{ background: "#E9ECEF" }}></Divider>
      <div className={classes.sortDiv}>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => {
              setOpenMonthModal(true);
            }}
            className={
              filterMonthValue ? classes.sortButtonActive : classes.sortButton
            }
          >
            {filterMonthValue ? filterMonthValue : "Měsíc"}
          </div>
          {openMonthModal && (
            <div>
              <div
                onClick={() => setOpenMonthModal(false)}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",

                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.months.map((object) => {
                  return (
                    <div
                      key={object.id}
                      onClick={() => {
                        if (filterLectorValue === "") {
                          setOpenMonthModal(false);

                          setFilterMonthValue(object.date);
                        }
                        setFilterMonth(object.id);
                      }}
                      className={classes.div}
                    >
                      {object.date}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => {
              if (filterLectorValue === "") {
                setOpenLectorModal(true);
              }
            }}
            className={
              filterLectorValue ? classes.sortButtonActive : classes.sortButton
            }
          >
            {filterLectorValue ? filterLectorValue : "Lektor"}
          </div>
          {openLectorModal && (
            <div>
              <div
                onClick={() => setOpenLectorModal(false)}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",

                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.lectors.map((object) => {
                  return (
                    <div
                      key={object.id}
                      onClick={() => {
                        if (filterLectorValue === "") {
                          setOpenLectorModal(false);

                          setFilterLectorValue(object.name);
                        }
                        setFilterLector(object.id);
                      }}
                      className={classes.div}
                    >
                      {object.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => {
              if (filterCategoryValue === "") {
                setOpenCategoryModal(true);
              }
            }}
            className={
              filterCategoryValue
                ? classes.sortButtonActive
                : classes.sortButton
            }
          >
            {filterCategoryValue ? filterCategoryValue : "Kategorie"}
          </div>
          {openCategoryModal && (
            <div>
              <div
                onClick={() => setOpenCategoryModal(false)}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",

                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.categories.map((object) => {
                  return (
                    <div
                      key={object.id}
                      onClick={() => {
                        setOpenCategoryModal(false);

                        setFilterCategoryValue(object.name);

                        setFilterCategory(object.id);
                      }}
                      className={classes.div}
                    >
                      {object.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {filterMonthValue || filterLectorValue || filterCategoryValue ? (
          <div className={classes.sortDivButtonDelete}>
            <div
              onClick={() => {
                setFilterLectorValue("");
                setFilterCategoryValue("");
                setFilterMonthValue("");
                setFilterLector(0);
                setFilterCategory(0);
                setFilterSwimmer("");
                setFilterMonth("");
                if (selectedButton !== "Všichni plavci") {
                  setSelectedButton("Všichni plavci");
                } else {
                }
              }}
              className={classes.sortButtonDelete}
            >
              Smazat filtr
            </div>
          </div>
        ) : null}
      </div>
      {loading && <Loading></Loading>}
      <div className={classes.table}>
        {valid === false ? (
          <p>
            Žádné lekce k zobrazení.{" "}
            <Link
              style={{ color: "#883677", fontWeight: 600 }}
              to="/dashboard-lessons"
            >
              Přihlašte se na lekci
            </Link>
          </p>
        ) : null}
        {myLessons.map((object) => {
          return (
            <div className={classes.tableEl} key={object.id}>
              <div>
                <p className={classes.label}>Bazén</p>
                <p className={classes.info}>{object.pool}</p>
              </div>
              <div>
                <p className={classes.label}>Datum</p>
                <p className={classes.info}>
                  {object.date}
                  {getDayName(object.date.replace(/\./g, "/"), "cs-CS") ===
                  "Invalid Date"
                    ? ""
                    : ` (${getDayName(
                        object.date.replace(/\./g, "/"),
                        "cs-CS"
                      )})`}
                </p>
              </div>
              <div>
                <p className={classes.label}>Čas</p>
                <p className={classes.info}>
                  {object.time_from}-{object.time_to}
                </p>
              </div>
              <div>
                <p className={classes.label}>Lektor</p>
                <p className={classes.info}>{object.lector}</p>
              </div>

              <div>
                <p className={classes.label}>Kategorie</p>
                <p className={classes.info}>{object.category}</p>
              </div>
              <div className={classes.swimmerInfo}>
                <p className={classes.label}>Plavec</p>
                <div style={{ width: "fit-content" }} className={classes.info}>
                  {object.swimmers.map((object) => {
                    return (
                      <div
                        style={{
                          position: "relative",
                          marginBottom: 10,
                        }}
                      >
                        <p>{object.first_name + " " + object.last_name}</p>
                        {object.paid === false ? (
                          <div
                            className={classes.miniInfo}
                            style={{
                              position: "absolute",
                              width: 20,
                              minWidth: 20,
                              top: "0px",
                              left: "110%",
                            }}
                          >
                            <div
                              onMouseEnter={() => setErrorInMouse(object.gwUrl)}
                              onMouseLeave={() => setErrorInMouse(false)}
                              onClick={() => {
                                submitHandler(object.gwUrl);
                              }}
                              style={{
                                width:
                                  !errorInMouse === object.gwUrl
                                    ? "30px"
                                    : "fit-content",
                                borderRadius: "15px",
                                padding: "4px 11px 4px 11px",

                                color: "white",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              className={classes.errorBox}
                            >
                              {errorInMouse === object.gwUrl ? (
                                <p style={{ fontSize: "12px" }}>
                                  Nezaplaceno{" "}
                                  <span style={{ fontSize: "10px" }}>
                                    (kliknutím zaplatit)
                                  </span>
                                </p>
                              ) : (
                                "!"
                              )}
                            </div>
                          </div>
                        ) : (
                          <div
                            className={classes.miniInfo}
                            style={{
                              position: "absolute",
                              width: 20,
                              minWidth: 20,
                              top: "0px",
                              left: "110%",
                            }}
                          >
                            <div
                              style={{
                                width:
                                  !errorInMouse === object.id
                                    ? "25px"
                                    : "fit-content",
                                borderRadius: "15px",
                                padding: "4px 5px 4px 4px",
                                color: "white",

                                fontSize: "12px",
                              }}
                              className={classes.paidBox}
                            >
                              OK
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  maxWidth: "none",
                  minWidth: "none",
                  marginLeft: 30,
                }}
                className={classes.buttonDiv}
              >
                {object.done === true ? (
                  "Lekce již proběhla"
                ) : (
                  <LogoutButton
                    deleteHandler={deleteHandler}
                    object={object}
                    token={props.token}
                    getMyLessons={getMyLessons}
                    userDataHandler={props.userDataHandler}
                    cancelLessonLoadingTrueHandler={
                      cancelLessonLoadingTrueHandler
                    }
                    cancelLessonLoadingFalseHandler={
                      cancelLessonLoadingFalseHandler
                    }
                  ></LogoutButton>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {openNotificationOrange && (
        <div style={{ position: "fixed", top: "70px", right: "20px" }}>
          <NotificationOrange label="Odhlášen z lekce"></NotificationOrange>
        </div>
      )}
    </div>
  );
};
export default MyLessons;
