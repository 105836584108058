import classes from "./ResetPassword.module.css";
import { useFormik } from "formik";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
const ResetPassword = (props) => {
  const [success, setSuccess] = useState();
  const sendResetLink = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/password/forgot-password",
        object,
        { headers }
      );
      // console.log(response);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setSuccess(error.response.data.errors);
      // console.log(success);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Povinný údaj";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: { email: "" },
    validate,
    onSubmit: (values) => {
      const object = {
        email: values.email,
      };
      sendResetLink(object);
      // console.log(values);
      values.email = "";
    },
  });

  return (
    <div className={classes.resetPassword}>
      <h3 className="h1">Zapomněli jste heslo?</h3>
      <p style={{ marginTop: "10px" }} className="p">
        Zadejte e-mail, který jste použili při registraci a my Vám zašleme odkaz
        pro bnovení hesla
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.resetPasswordForm}
      >
        <input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Email"
          name="email"
          type="email"
        ></input>
        {formik.touched.email && formik.errors.email && (
          <div className="errorInput">{formik.errors.email}</div>
        )}
        {success === false && (
          <p style={{ textAlign: "center", marginTop: 10, marginBottom: 5 }}>
            Uživatel nenalezen nebo se něco pokazilo
          </p>
        )}
        {success === true && (
          <p style={{ textAlign: "center", marginTop: 10, marginBottom: 5 }}>
            Odkaz pro resetování hesla Vám byl poslán na zadaný e-mail
          </p>
        )}

        <Button label="Obnovit heslo" type="submit"></Button>
      </form>
      <div className={classes.optionsDiv}>
        <Link className="link" to="/">
          <p>Přihlásit se</p>
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
