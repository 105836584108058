import classes from "./MySwimmers.module.css";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
const ChangeInfoForm = (props) => {
  const changeSwimmerHandler = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    try {
      const response = await axios.patch(
        "https://login.mtmacademy.cz/api/v1/update-swimmer",
        object,
        { headers }
      );
      // console.log(response);
      props.getSwimmers();
      props.userSwimmersHandler();
    } catch (error) {
      console.log(error.response);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Povinný údaj";
    }
    if (!values.surname) {
      errors.surname = "Povinný údaj";
    }
    if (!values.category) {
      errors.category = "Povinný údaj";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: { name: "", surname: "", category: "" },
    validate,
    onSubmit: (values) => {
      const dataToChange = {
        id: props.dataToChange.id,
        first_name: values.name,
        last_name: values.surname,
        category_id: parseInt(values.category),
      };
      // console.log(dataToChange);
      changeSwimmerHandler(dataToChange);
      props.getSwimmers();
      props.closePopup();
    },
  });

  return (
    <div className={classes.modal}>
      <CloseIcon
        onClick={props.closePopup}
        fontSize="large"
        style={{
          position: "absolute",
          cursor: "pointer",
          top: "15px",
          right: "15px",
          color: "grey",
        }}
      ></CloseIcon>
      <h2 className="h1">Změnit údaje</h2>

      <form onSubmit={formik.handleSubmit} className={classes.addSwimmerForm}>
        <input
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={props.dataToChange.first_name}
          name="name"
          type="text"
        ></input>
        {formik.touched.name && formik.errors.name && (
          <div style={{ marginBottom: "10px" }} className="errorInput">
            {formik.errors.name}
          </div>
        )}
        <input
          value={formik.values.surname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={props.dataToChange.last_name}
          name="surname"
          type="text"
        ></input>
        {formik.touched.surname && formik.errors.surname && (
          <div style={{ marginBottom: "10px" }} className="errorInput">
            {formik.errors.surname}
          </div>
        )}
        <select
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="category"
          style={{ height: "55px", border: "1px solid #ADB5BD" }}
          defaultValue={props.dataToChange.category}
        >
          <option disabled value={props.dataToChange.category}>
            Kategorie
          </option>
          {props.categories.map((object) => {
            return <option value={object.id}>{object.name}</option>;
          })}
        </select>
        {formik.touched.category && formik.errors.category && (
          <div
            style={{ marginBottom: "10px", marginTop: "3px" }}
            className="errorInput"
          >
            {formik.errors.category}
          </div>
        )}
        <button type="submit">Uložit</button>
      </form>
    </div>
  );
};
export default ChangeInfoForm;
