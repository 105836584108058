import classes from "./MySwimmers.module.css";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import ChangeInfoForm from "./ChangeInfoForm";
import PopupListOptions from "./PopupListOptions/PopupListOptions";
import CloseIcon from "@mui/icons-material/Close";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import Loading from "../../UI/Loading";
import NotificationOrange from "../../UI/NotificationOrange";
import NotificationRed from "../../UI/NotificationRed";


const MySwimmers = (props) => {
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const [filteredArray, setFilteredArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNotificationOrange, setOpenNotificationOrange] = useState(false);
  const [openNotificationRed, setOpenNotificationRed] = useState(false);

  const getSwimmers = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        "https://login.mtmacademy.cz/api/v1/my-swimmers",
        { headers }
      );
      setFilteredArray(response.data.data);
      setLoading(false);
      props.userSwimmersHandler();
      // console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getSwimmers();
  }, []);

  const addNewSwimmer = async (values) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };

    try {
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/create-swimmer",
        values,
        { headers }
      );
      getSwimmers();
    } catch (error) {
      console.log(error.response);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [dataToChange, setDataToChange] = useState({});
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const popupOpenHandler = (value) => {
    setPopupOpen(value);
  };
  const closeModalHandler = () => {
    setPopupOpen(!popupOpen);
  };
  const deletePopupHandler = () => {
    setDeletePopupOpen(!deletePopupOpen);
    // console.log(deletePopupOpen);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Povinný údaj";
    }
    if (!values.surname) {
      errors.surname = "Povinný údaj";
    }
    if (!values.category) {
      errors.category = "Povinný údaj";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { name: "", surname: "", category: "" },
    validate,
    onSubmit: (values) => {
      const newSwimmer = {
        first_name: values.name,
        last_name: values.surname,
        category_id: values.category,
      };
      addNewSwimmer(newSwimmer);

      props.userSwimmersHandler();
      // console.log(props.swimmers);
      setModalOpen(false);
      getSwimmers();
      values.name = "";
      values.surname = "";
      values.category = "";
    },
  });
  const deleteHandler = (object) => {
    const deleteObject = async (object) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${props.token}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.delete(
          `https://login.mtmacademy.cz/api/v1/delete-swimmer/${object.id}`,

          { headers }
        );
        // console.log(response);
        setOpenNotificationOrange(true);
        setTimeout(closeNotificationOrange, 2000);
        setConfirm(!confirm);
        props.userDataHandler();
      } catch (error) {
        console.log(error.response);
        setOpenNotificationRed(true);
        setTimeout(closeNotificationRed, 2000);
      }
    };
    deleteObject(object);
  };
  function closeNotificationOrange() {
    setOpenNotificationOrange(false);
  }
  function closeNotificationRed() {
    setOpenNotificationRed(false);
  }

  return (
    <div className="contentContainer">
      <div className={classes.swimmersHeader}>
        <h1 className="h1">Plavci</h1>
        <div className={classes.headerButton}>
          <button onClick={() => setModalOpen(!modalOpen)}>
            Přidat Plavce
          </button>
        </div>
      </div>
      {loading && <Loading></Loading>}
      <div className={classes.table}>
        {filteredArray.map((object) => {
          return (
            <div className={classes.tableEl} key={object.id}>
              <div className={classes.tableElDiv}>
                <p className={classes.label}>Jméno</p>
                <p className={classes.info}>{object.first_name}</p>
              </div>
              <div className={classes.tableElDiv}>
                <p className={classes.label}>Přijmení</p>
                <p className={classes.info}>{object.last_name}</p>
              </div>
              <div className={classes.tableElDiv}>
                <p className={classes.label}>Kategorie</p>
                <p className={classes.info}>{object.category}</p>
              </div>
              <div className={classes.tableElDiv}>
                <p className={classes.label}>Druh účtu</p>
                <p className={classes.info}>
                  {object.role === "swimmer" ? "Plavec" : object.role}
                </p>
              </div>
              <div
                onClick={() => {
                  setDataToChange(object);
                }}
                className={classes.popup}
              >
                <PopupListOptions
                  deletePopupHandler={deletePopupHandler}
                  popupOpen={popupOpen}
                  popupOpenHandler={popupOpenHandler}
                  object={object}
                  getSwimmers={getSwimmers}
                  userSwimmersHandler={props.userSwimmersHandler}
                ></PopupListOptions>
              </div>
            </div>
          );
        })}
      </div>
      {modalOpen && (
        <div>
          <div className={classes.modal}>
            <CloseIcon
              onClick={() => {
                setModalOpen(!modalOpen);
                formik.resetForm();
              }}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>

            <h2 className="h1">Přidat plavce</h2>
            <form
              onSubmit={formik.handleSubmit}
              className={classes.addSwimmerForm}
            >
              <input
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Jméno"
                name="name"
                type="name"
              ></input>
              {formik.touched.name && formik.errors.name && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {formik.errors.name}
                </div>
              )}
              <input
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Přijmení"
                name="surname"
                type="surname"
              ></input>
              {formik.touched.surname && formik.errors.surname && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {formik.errors.surname}
                </div>
              )}
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="category"
                style={{ height: "55px", border: "1px solid #ADB5BD" }}
                defaultValue="Kategorie"
              >
                <option disabled value="Kategorie">
                  Kategorie
                </option>
                {props.categories.map((object) => {
                  return <option value={object.id}>{object.name}</option>;
                })}
                {/* <option value={4}>Dospělí</option>
                <option value={3}>Junioři 12-18 let</option>
                <option value={2}>Děti 7-11 let</option>
                <option value={1}>Děti 4-6 let</option> */}
              </select>
              {formik.touched.category && formik.errors.category && (
                <div
                  style={{ marginBottom: "10px", marginTop: "3px" }}
                  className="errorInput"
                >
                  {formik.errors.category}
                </div>
              )}
              <button type="submit">Přidat plavce</button>
            </form>
          </div>
          <div
            onClick={() => {
              setModalOpen(!modalOpen);
              formik.resetForm();
            }}
            className="backdrop"
          >
            {" "}
          </div>
        </div>
      )}
      {popupOpen && (
        <div>
          <div
            onClick={() => setPopupOpen(!popupOpen)}
            className="backdrop"
          ></div>
          <ChangeInfoForm
            closePopup={closeModalHandler}
            dataToChange={dataToChange}
            token={props.token}
            getSwimmers={getSwimmers}
            categories={props.categories}
          ></ChangeInfoForm>
        </div>
      )}
      {deletePopupOpen && (
        <div>
          <div
            onClick={() => setDeletePopupOpen(!deletePopupOpen)}
            className="backdrop"
          ></div>
          <div className={classes.modal}>
            <CloseIcon
              onClick={() => {
                setDeletePopupOpen(!deletePopupOpen);
              }}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>
            <h2 style={{ marginRight: "50px" }} className="h2">
              Opravdu chcete smazat uživatele: {dataToChange.first_name}?
            </h2>
            <div className={classes.confirmButtonDiv}>
              <button
                onClick={() => setDeletePopupOpen(!deletePopupOpen)}
                className={classes.disagreeButton}
              >
                Zrušit
              </button>
              <button
                onClick={() => {
                  // console.log(dataToChange);
                  deleteHandler(dataToChange);
                  setDeletePopupOpen(!deletePopupOpen);
                }}
                className={classes.confirmButton}
              >
                Smazat
              </button>
            </div>
          </div>
        </div>
      )}
      {confirm && (
        <div>
          <div
            onClick={() => {
              setConfirm(!confirm);
              getSwimmers();
            }}
            className="backdrop"
          ></div>
          <div className={classes.modal}>
            <h2 className="h2">Plavec byl smazán</h2>
            <p style={{ marginBottom: "-10px", marginTop: "10px" }}>
              Také byl odhlášen ze všech lekcí
            </p>
            <button
              onClick={() => {
                setConfirm(!confirm);
                getSwimmers();
                props.userDataHandler();
              }}
            >
              Rozumím
            </button>
          </div>
        </div>
      )}
      {openNotificationOrange && (
        <div style={{ position: "fixed", top: "70px", right: "20px" }}>
          <NotificationOrange label="Odhlášen z lekce"></NotificationOrange>
        </div>
      )}
      {openNotificationRed && (
        <div style={{ position: "fixed", top: "70px", right: "20px" }}>
          <NotificationRed
            label="Něco se pokazilo"
            content="Plavce se nepodařilo smazat"
          ></NotificationRed>
        </div>
      )}
    </div>
  );
};
export default MySwimmers;
