import classes from "./PopupListOptions.module.css";
import infoIcon from "../CTA.svg";
import { useState } from "react";

const PopupListOptions = (props) => {
  const popupOpenHandler = () => {
    props.popupOpenHandler(!props.popupOpen);
  };
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <div style={{ position: "relative", zIndex: "2" }}>
      <img
        onClick={() => {
          setPopupOpen(!popupOpen);
        }}
        alt="option"
        src={infoIcon}
        style={{ cursor: "pointer", zIndex: "2" }}
      ></img>
      {popupOpen && (
        <div>
          <div
            onClick={() => setPopupOpen(!popupOpen)}
            className={classes.backdrop}
          ></div>
          <div className={classes.popup}>
            <div style={{ cursor: "pointer" }} onClick={popupOpenHandler}>
              Upravit
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.deletePopupHandler();
              }}
            >
              Smazat
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PopupListOptions;
