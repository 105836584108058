import classes from "./Registration.module.css";
import { useFormik } from "formik";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Registration = () => {
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [valid, setValid] = useState(false);

  const registration = async (values) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    try {
      // console.log(values);
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/auth/sign-up",
        values,
        { headers }
      );
      // console.log(response);
      setValid(true);
    } catch (error) {
      if (error.response.data.error.email) {
        setErrorEmail(error.response.data.error.email);
      }
      if (error.response.data.error.password) {
        setErrorPassword(error.response.data.error.password);
      }
      console.log(error.response);
      console.log(errorPassword + errorEmail);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Povinný údaj";
    }
    if (!values.first_name) {
      errors.first_name = "Povinný údaj";
    }
    if (!values.last_name) {
      errors.last_name = "Povinný údaj";
    }
    if (!values.phone) {
      errors.phone = "Povinný údaj";
    } else if (values.phone.length < 9) {
      errors.phone = "Chybný formát telefoního čísla";
    }

    if (!values.password) {
      errors.password = "Povinný údaj";
    }
    if (!values.password_again) {
      errors.password_again = "Povinný údaj";
    } else if (values.password_again !== values.password) {
      errors.password_again = "Heslo se neshodují";
    }
    if (values.agreed === false) {
      errors.agreed = "Povinný údaj";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      password: "",
      password_again: "",
      agreed: false,
      swimmer_too: false,
      newsletter: false,
    },
    validate,
    onSubmit: (values) => {
      // console.log(values);
      registration({
        email: values.email,
        password: values.password,
        password_again: values.password_again,
        newsletter: values.newsletter,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: parseInt(values.phone),
        swimmer_too: values.swimmer_too,
      });

      values.email = "";
      values.first_name = "";
      values.last_name = "";
      values.password = "";
      values.password_again = "";
      values.phone = "";
    },
  });

  return (
    <div className={classes.registration}>
      {valid ? <Redirect to="/"></Redirect> : null}
      <div className={classes.registrationDiv}>
        <h3 className="h1">Vytvořit účet</h3>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.registrationForm}
        >
          <input
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Jméno"
            name="first_name"
            type="name"
          ></input>
          {formik.touched.first_name && formik.errors.first_name && (
            <div className="errorInput">{formik.errors.first_name}</div>
          )}
          <input
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Přijmení"
            name="last_name"
            type="surname"
          ></input>
          {formik.touched.last_name && formik.errors.last_name && (
            <div className="errorInput">{formik.errors.last_name}</div>
          )}
          <input
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Email"
            name="email"
            type="email"
          ></input>
          {formik.touched.email && formik.errors.email && (
            <div className="errorInput">{formik.errors.email}</div>
          )}
          <input
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Telefon"
            name="phone"
            type="tel"
          ></input>
          {formik.touched.phone && formik.errors.phone && (
            <div className="errorInput">{formik.errors.phone}</div>
          )}
          <input
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Heslo"
            name="password"
            type="password"
          ></input>
          {formik.touched.password && formik.errors.password && (
            <div className="errorInput">{formik.errors.password}</div>
          )}
          <input
            value={formik.values.password_again}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Heslo po druhé"
            name="password_again"
            type="password"
          ></input>
          {formik.touched.password_again && formik.errors.password_again && (
            <div className="errorInput">{formik.errors.password_again}</div>
          )}
          <div style={{ marginBottom: "20px" }}>
            <div className={classes.checkboxDiv}>
              <input
                onChange={formik.handleChange}
                name="agreed"
                value={true}
                className="checkbox"
                type="checkbox"
                id="agreed"
              ></input>

              <div>
                <label htmlFor="agreed">
                  Souhlasím se všeobecnými podmínkami
                </label>
              </div>
            </div>
            {formik.touched.agreed && formik.errors.agreed && (
              <div style={{ marginTop: "10px" }} className="errorInput">
                {formik.errors.agreed}
              </div>
            )}
            <div className={classes.checkboxDiv}>
              <input
                onChange={formik.handleChange}
                name="swimmer_too"
                value={true}
                className="checkbox"
                type="checkbox"
                id="swimmer"
              ></input>

              <div>
                <label htmlFor="swimmer_too">Budete také plavec</label>
              </div>
            </div>
            <div className={classes.checkboxDiv}>
              <input
                onChange={formik.handleChange}
                name="newsletter"
                value={true}
                className="checkbox"
                type="checkbox"
                id="newsletter"
              ></input>

              <div>
                <label htmlFor="newsletter">
                  Přeji zasílat novinky ze světa MTM Academy.
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "10px" }} className="errorInput">
            {errorPassword}
          </div>
          <div className="errorInput">{errorEmail}</div>
          <Button label="Vytvořit účet" type="submit"></Button>
        </form>
        <div className={classes.optionsDiv}>
          <Link className="link" to="/">
            <p>Přihlásit se</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Registration;
