import classes from "./Lessons.module.css";
import { Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import NotificationGreen from "../../UI/NoticationGreen";
import NotificationOrange from "../../UI/NotificationOrange";
import CloseIcon from "@mui/icons-material/Close";
import ActionButton from "./ActionButton";
import axios from "axios";
import Loading from "../../UI/Loading";
import NotificationRed from "../../UI/NotificationRed";
import ActionButtonSecondary from "./ActionButtonSecondary";

const Lessons = (props) => {
  const [lessons, setLessons] = useState(props.lessons);
  const [expand, setExpand] = useState();
  const [date, setDate] = useState();

  const [token, setToken] = useState();
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  useEffect(() => {
    setToken(getCookie("XSRF-TOKEN"));
  }, []);

  const [filterLector, setFilterLector] = useState(0);
  const [filterCategory, setFilterCategory] = useState(0);
  const [filterPool, setFilterPool] = useState(0);
  const [filterMonth, setFilterMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [openNotificationRed, setOpenNotificationRed] = useState(false);

  const openNotificationRedHandler = () => {
    setOpenNotificationRed(true);
  };
  const closeNotificationRedHandler = () => {
    setOpenNotificationRed(false);
  };
  const getPayMethods = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        `https://login.mtmacademy.cz/api/v1/pay-methods`,
        { headers }
      );

      // console.log(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getLessons = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        `https://login.mtmacademy.cz/api/v1/lessons?pool_id=${filterPool}&category_id=${filterCategory}&lector_id=${filterLector}&month_id=${filterMonth}`,
        { headers }
      );
      setLessons(response.data.data);
      setLoading(false);

      // console.log(response.data.data);

      if (response.data.data.length === 0) {
        setValid(false);
      } else {
        setValid(true);
      }

      // console.log(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };
  const getPackageLessons = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        `https://login.mtmacademy.cz/api/v1/lessons-package?pool_id=${filterPool}&category_id=${filterCategory}&lector_id=${filterLector}&month_id=${filterMonth}`,
        { headers }
      );
      setLoading(false);
      if (response.data.data.length === 0) {
        setValid(false);
      } else {
        setValid(true);
      }
      setLessons(response.data.data);
      // console.log("hi");

      // console.log(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    if (activeArray === "single") {
      getLessons();
      getPayMethods();
    } else if (activeArray === "package") {
      getPackageLessons();
    }
  }, [filterLector, filterCategory, filterPool, filterMonth]);

  const takeLessons = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    setLoading(true);

    try {
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/take-lesson",
        object,
        { headers }
      );

      // console.log(response.data);

      // if (!response.data.data.gwUrl) {
      //   getLessons();
      //   props.userDataHandler();
      // }

      return response.data.data;
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      openNotificationRedHandler();
      setTimeout(closeNotificationRedHandler, 3000);
    }
  };
  const takePackageLessons = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/take-package",
        object,
        { headers }
      );
      // getPackageLessons();
      // console.log(response.data);
      // setLoading(false);
      return response.data.data;
    } catch (error) {
      console.log(error.response);
    }
  };
  const cancelLesson = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/cancel-lesson",
        object,
        { headers }
      );

      // console.log(response);
      getLessons();
      setTimeout(closeNotificationOrange, 2000);
      props.userDataHandler();
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [filteredValue, setFilteredValue] = useState(props.lessons);
  const [filteredValueCategory, setFilteredValueCategory] = useState(
    props.lessons
  );
  const [activeArray, setActiveArray] = useState("single");
  const [data, setData] = useState({});
  const [clicked, setClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Všichni bazény");
  const [openNotificationOrange, setOpenNotificationOrange] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [filteredMonthModal, setFilteredMonthModal] = useState(false);
  const [filteredLektorModal, setFilteredLektorModal] = useState(false);
  const [filteredCategoryModal, setFilteredCategoryModal] = useState(false);
  const [filterPoolValue, setFilterPoolValue] = useState("");
  const [filterMonthValue, setFilterMonthValue] = useState("");
  const [filterLectorValue, setFilterLectorValue] = useState("");
  const [filterCategoryValue, setFilterCategoryValue] = useState("");
  const [openPaymentWall, setOpenPaymentWall] = useState(false);

  const findObject = (object1, object2) => {
    var newObject = [];
    var backupArray = [...object1];

    for (var i = 0; i < object2.length; i++) {
      newObject.push(object1.findIndex((item) => item.id === object2[i].id));
    }
    for (var i = 0; i < newObject.length; i++) {
      backupArray.splice(newObject[i], 1);
    }
    // console.log(backupArray);

    return backupArray;

    // var swimmer = object1.findIndex((item) => item.id === object[i].id);

    // return object1.splice(swimmer, 1);
  };

  const filterHandler = (object) => {
    setClicked(!clicked);
    setFilterLectorValue("");
    setFilterCategoryValue("");
  };

  function closeNotificationGreen() {
    setOpenNotification(false);
  }
  function closeNotificationOrange() {
    setOpenNotificationOrange(false);
  }
  const validate = (values) => {
    const errors = {};
    if (!values.swimmer) {
      errors.swimmer = "Povinný údaj";
    }
    return errors;
  };
  const submitHandler = async (object) => {
    const res =
      activeArray === "package"
        ? await takePackageLessons(object)
        : await takeLessons(object);
    handlePay({ gwUrl: res.gwUrl });
    // console.log(res.gwUrl);
    setLoading(false);
  };
  const handlePay = (createResult) => {
    try {
      window._gopay.checkout(
        { gatewayUrl: createResult.gwUrl, inline: true },
        async (checkoutResult) => {
          console.log(
            `Stav platby ${checkoutResult.id}: ${checkoutResult.state}`
          );
          if (checkoutResult && checkoutResult.state === "PAID") {
            getLessons();
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: { swimmer: "", payment: "3" },
    validate,
    onSubmit: (values) => {
      if (values.payment === "online") {
        values.payment = "3";
      }

      setOpenModal(!openModal);

      const dataToSend = {
        swimmer_id: parseInt(values.swimmer),
        lesson_time_id: data.id,
        pay_method: parseInt(values.payment),
      };
      const dataToSendPackage = {
        swimmer_id: parseInt(values.swimmer),
        package_id: data.id,
        pay_method: parseInt(values.payment),
      };
      // console.log(dataToSend);

      if (activeArray === "package") {
        submitHandler(dataToSendPackage);
      } else {
        submitHandler(dataToSend);
      }

      values.swimmer = "";
      values.payment = "online";

      setData(data);
    },
  });
  const buttonFunctionHandler = (object) => {
    setOpenModal(true);
    setData(object);
  };

  const buttonFunctionHandlersecondary = (object) => {
    setData(object);
    props.setCreditHandler(object.price);
    setData((object.signed = object.signed - 1));
    setData((object.myLesson = false));
    setOpenNotificationOrange(!openNotificationOrange);
  };
  function getDayName(dateStr, locale) {
    var dateObject = dateStr.split("/");
    const day = dateObject.splice(0, 1)[0];

    const month = dateObject.splice(0, 1)[0];
    const year = dateObject.splice(0, 1)[0];
    const newDate = `${month}/${day}/${year}`;
    const validDate = new Date(newDate);

    return validDate.toLocaleDateString(locale, { weekday: "long" });
  }

  return (
    <div className="contentContainer">
      <h1 className="h1">Přehled lekcí</h1>
      <div className={classes.tabsDiv}>
        <div
          onClick={() => {
            setActiveArray("single");
            setFilteredValue(props.lessons);
            setFilteredValueCategory(props.lessons);
            setFilterLectorValue("");
            setFilterCategoryValue("");
            setFilterMonthValue("");
            setSelectedButton("Všichni bazény");
            setLessons(props.lessons);
            setExpand();
          }}
          className={activeArray === "single" ? classes.tab : classes.tabDis}
        >
          Jednotlivé lekce
        </div>

        <div
          onClick={() => {
            setActiveArray("package");
            setFilteredValue(props.lessonsPackage);
            setFilteredValueCategory(props.lessonsPackage);
            setFilterLectorValue("");
            setFilterCategoryValue("");
            setFilterMonthValue("");

            setSelectedButton("Všichni bazény");
            setLessons(props.lessonsPackage);
          }}
          className={activeArray === "package" ? classes.tab : classes.tabDis}
        >
          Balíčky lekcí
        </div>
      </div>
      <Divider style={{ background: " #E9ECEF" }}></Divider>
      <div className={classes.tabsDiv}>
        <div
          onClick={() => {
            if (activeArray === "package") {
              setFilteredValueCategory(props.packageLessons);
              setFilteredValue(props.packageLessons);
            } else {
              setFilteredValueCategory(props.lessons);
            }

            setFilterPoolValue("Všichni bazény");
            setSelectedButton("Všichni bazény");

            setFilterPool(0);
            setFilterCategory(0);
            setFilterLector(0);
            setFilterMonth("");
          }}
          className={
            selectedButton === "Všichni bazény" ? classes.tab : classes.tabDis
          }
        >
          Všechny bazény
        </div>
        {props.pools.map((object) => {
          return (
            <div
              onClick={() => {
                filterHandler(object.name);
                setSelectedButton(object.name);
                setFilterPool(object.id);
              }}
              key={object.id}
              className={
                object.name === selectedButton ? classes.tab : classes.tabDis
              }
            >
              {object.name}
            </div>
          );
        })}
      </div>
      <Divider style={{ background: " #E9ECEF" }}></Divider>
      <div className={classes.sortDiv}>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => setFilteredMonthModal(true)}
            className={
              filterMonthValue ? classes.sortButtonActive : classes.sortButton
            }
          >
            {filterMonthValue ? filterMonthValue : "Měsíc"}
          </div>
          {filteredMonthModal && (
            <div>
              <div
                onClick={() => setFilteredMonthModal(false)}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",

                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.months.map((object) => {
                  return (
                    <div key={object.id}>
                      <div
                        className={classes.div}
                        onClick={() => {
                          if (filterLectorValue === "") {
                            setFilteredMonthModal(false);

                            setFilterMonthValue(object.date);
                            setFilterMonth(object.id);
                          }
                        }}
                      >
                        {object.date}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => {
              if (filterLectorValue === "") {
                setFilteredLektorModal(true);
              }
            }}
            className={
              filterLectorValue ? classes.sortButtonActive : classes.sortButton
            }
          >
            {filterLectorValue ? filterLectorValue : "Lektor"}
          </div>

          {filteredLektorModal && (
            <div>
              <div
                onClick={() => {
                  setFilteredLektorModal(false);
                }}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",
                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.lectors.map((object) => {
                  return (
                    <div key={object.name}>
                      <div
                        className={classes.div}
                        onClick={() => {
                          if (filterLectorValue === "") {
                            setFilteredLektorModal(false);
                            setFilterLectorValue(object.name);
                            setFilterLector(object.id);
                          }
                        }}
                      >
                        {object.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.sortDivButton}>
          <div
            onClick={() => {
              if (filterCategoryValue === "") {
                setFilteredCategoryModal(true);
              }
            }}
            className={
              filterCategoryValue
                ? classes.sortButtonActive
                : classes.sortButton
            }
          >
            {filterCategoryValue ? filterCategoryValue : "Kategorie"}
          </div>
          {filteredCategoryModal && (
            <div>
              <div
                onClick={() => {
                  setFilteredCategoryModal(false);
                }}
                style={{
                  position: "fixed",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100vh",

                  zIndex: "2",
                }}
              ></div>
              <div className={classes.sortList}>
                {props.categories.map((object) => {
                  return (
                    <div
                      className={classes.div}
                      key={object.name}
                      onClick={() => {
                        if (filterCategoryValue === "") {
                          setFilteredCategoryModal(false);
                          setFilterCategoryValue(object.name);
                          setFilterCategory(object.id);
                        }
                      }}
                    >
                      {object.name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {filterMonthValue || filterLectorValue || filterCategoryValue ? (
          <div className={classes.sortDivButtonDelete}>
            <div
              onClick={() => {
                setFilterLectorValue("");
                setFilterCategoryValue("");
                setFilterMonthValue("");
                setFilterPool(0);
                setFilterCategory(0);
                setFilterLector(0);
                setFilterMonth("");
                setSelectedButton("Všichni bazény");
              }}
              className={classes.sortButtonDelete}
            >
              Smazat filtr
            </div>
          </div>
        ) : null}
      </div>
      {loading && <Loading></Loading>}
      <div
        style={{ width: "100%", textAlign: "center", marginTop: "40px" }}
      ></div>
      <div className={classes.table}>
        {valid === false ? (
          <p style={{ textAlign: "center", width: "100%" }}>
            Žádné lekce k zobrazení.{" "}
          </p>
        ) : null}
        {lessons.map((object) => {
          return (
            <div
              style={{
                display: "relative",
                // height: `${expand === object.id ? "300px" : "fit-content"}`,
                transition: "0.3s",
                flexWrap: "wrap",
              }}
              className={classes.tableEl}
              key={object.id}
            >
              <div>
                <p className={classes.label}>Bazén</p>
                <p className={classes.info}>{object.pool}</p>
              </div>
              <div>
                <p className={classes.label}>Datum</p>
                <p className={classes.info}>
                  {object.date}
                  {getDayName(object.date.replace(/\./g, "/"), "cs-CS") ===
                  "Invalid Date"
                    ? ""
                    : ` (${getDayName(
                        object.date.replace(/\./g, "/"),
                        "cs-CS"
                      )})`}
                </p>
              </div>
              <div>
                <p className={classes.label}>Čas</p>
                <p className={classes.info}>
                  {object.common_time
                    ? object.common_time
                    : `${object.time_from} : ${object.time_to}`}
                </p>
              </div>
              <div>
                <p className={classes.label}>Lektor</p>
                <p className={classes.info}>{object.lector}</p>
              </div>
              <div>
                <p className={classes.label}>Kategorie</p>
                <p className={classes.info}>{object.category}</p>
              </div>
              {object.lessons_quantity && (
                <div>
                  <p className={classes.label}>Počet lekcí</p>
                  <p className={classes.info}>{object.lessons_quantity}</p>
                </div>
              )}
              {!object.price ? (
                <div>
                  <p className={classes.label}>Cena</p>
                  <p className={classes.info}>Neuvedeno</p>
                </div>
              ) : object.price === 0 ? (
                <div>
                  <p className={classes.label}>Cena</p>
                  <p className={classes.info}>{object.price} Kč</p>
                </div>
              ) : (
                <div>
                  <p className={classes.label}>Cena</p>
                  <p className={classes.info}>{object.price} Kč</p>
                </div>
              )}
              <div>
                <p className={classes.label}>Kapacita</p>
                <p className={classes.info}>{object.capacity}</p>
              </div>

              {object.taken && object.free && activeArray !== "package" ? (
                <div
                  className={classes.buttonDiv}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "1",
                    minWidth: "150px",
                  }}
                >
                  <ActionButtonSecondary
                    buttonFunctionHandler={buttonFunctionHandler}
                    buttonFunctionHandlersecondary={
                      buttonFunctionHandlersecondary
                    }
                    cancelLesson={cancelLesson}
                    object={object}
                  ></ActionButtonSecondary>
                </div>
              ) : null}

              <div
                className={classes.buttonDiv}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1",
                  minWidth: "150px",
                }}
              >
                <ActionButton
                  buttonFunctionHandler={buttonFunctionHandler}
                  buttonFunctionHandlersecondary={
                    buttonFunctionHandlersecondary
                  }
                  cancelLesson={cancelLesson}
                  object={object}
                  activeArray={activeArray}
                ></ActionButton>
              </div>

              {object.lessons && (
                <div className={classes.expand}>
                  <button
                    onClick={() => {
                      if (object.id === expand) {
                        setExpand();
                      } else {
                        setExpand(object.id);
                      }
                    }}
                  >
                    <p
                      style={{
                        transform: `translateY(0px) rotate(${
                          expand === object.id ? "45deg" : "0deg"
                        })`,
                        transition: "0.3s",
                      }}
                    >
                      +
                    </p>
                  </button>
                </div>
              )}
              {expand === object.id && (
                <div
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "2px",
                      marginBottom: "10px",
                      background: "black",
                      marginTop: "20px",
                    }}
                  ></div>
                  {object.lessons &&
                    object.lessons.map((object) => {
                      return (
                        <div
                          style={{ background: "white" }}
                          className={classes.tableEl}
                        >
                          <div style={{ maxWidth: "100px" }}>
                            <p className={classes.label}>Bazén</p>
                            <p className={classes.info}>{object.pool}</p>
                          </div>
                          <div style={{ maxWidth: "100px" }}>
                            <p className={classes.label}>Datum</p>
                            <p className={classes.info}>
                              {object.date}
                              {getDayName(
                                object.date.replace(/\./g, "/"),
                                "cs-CS"
                              ) === "Invalid Date"
                                ? ""
                                : ` (${getDayName(
                                    object.date.replace(/\./g, "/"),
                                    "cs-CS"
                                  )})`}
                            </p>
                          </div>
                          <div style={{ maxWidth: "100px" }}>
                            <p className={classes.label}>Čas</p>
                            <p className={classes.info}>
                              {object.common_time
                                ? object.common_time
                                : `${object.time_from} : ${object.time_to}`}
                            </p>
                          </div>
                          <div style={{ maxWidth: "100px" }}>
                            <p className={classes.label}>Lektor</p>
                            <p className={classes.info}>{object.lector}</p>
                          </div>
                          <div style={{ maxWidth: "100px" }}>
                            <p className={classes.label}>Kategorie</p>
                            <p className={classes.info}>{object.category}</p>
                          </div>
                          {object.lessons_quantity && (
                            <div style={{ maxWidth: "100px" }}>
                              <p className={classes.label}>Počet lekcí</p>
                              <p className={classes.info}>
                                {object.lessons_quantity}
                              </p>
                            </div>
                          )}
                          {/* {!object.price ? (
                            <div>
                              <p className={classes.label}>Cena</p>
                              <p className={classes.info}>Neuvedeno</p>
                            </div>
                          ) : object.price === 0 ? (
                            <div>
                              <p className={classes.label}>Cena</p>
                              <p className={classes.info}>{object.price} Kč</p>
                            </div>
                          ) : (
                            <div>
                              <p className={classes.label}>Cena</p>
                              <p className={classes.info}>{object.price} Kč</p>
                            </div>
                          )} */}
                          {/* <div>
                            <p className={classes.label}>Kapacita</p>
                            <p className={classes.info}>{object.capacity}</p>
                          </div> */}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {openModal && (
        <div>
          <div
            className={classes.backdrop}
            onClick={() => setOpenModal(!openModal)}
          ></div>
          <div style={{ position: "fixed" }} className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenModal(!openModal)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>

            <h2 className={"h2"}>Přihlásit</h2>
            <h5
              style={{
                fontSize: "16px",
                marginTop: "16px",
                fontWeight: "500",
              }}
            >
              DETAIL LEKCE
            </h5>
            <p className="p" style={{ margin: "5px 0px" }}>
              Bazén: <span>{data.bazen}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Datum: <span>{data.date}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Čas:{" "}
              <span>
                {data.time_from} : {data.time_to}
                {data.common_time}
              </span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Lektor: <span>{data.lector}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Kategorie: <span>{data.category}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Cena: <span>{data.price}</span> Kč
            </p>
            <form onSubmit={formik.handleSubmit}>
              <select
                className={classes.select}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="swimmer"
                defaultValue="Plavec"
                // onClick={() => {
                //   console.log(findObject(props.swimmers, data.swimmers));
                // }}
              >
                <option value="Plavec" disabled>
                  Plavec
                </option>
                {data.swimmers &&
                  findObject(props.swimmers, data.swimmers)
                    .filter((swimmer) => swimmer.category === data.category)
                    .map((swimmer) => {
                      return (
                        <option value={swimmer.id} key={swimmer.id}>
                          {swimmer.first_name}
                        </option>
                      );
                    })}
              </select>
              {formik.touched.swimmer && formik.errors.swimmer && (
                <div style={{ margin: "7px 0px" }} className="errorInput">
                  {formik.errors.swimmer}
                </div>
              )}

              <h5
                style={{
                  fontSize: "16px",
                  marginTop: "16px",
                  fontWeight: "500",
                }}
              >
                PLATBA
              </h5>
              <fieldset
                onChange={formik.handleChange}
                name="payment"
                id="payment"
              >
                <div>
                  <input
                    className={classes.radio}
                    value="3"
                    type="radio"
                    id="payment"
                    name="payment"
                    defaultChecked
                  ></input>
                  <label htmlFor="payment">Kartou on-line</label>
                </div>

                {props.userData.credit >= data.price ? (
                  <div>
                    <input
                      className={classes.radio}
                      value="1"
                      type="radio"
                      id="payment"
                      name="payment"
                    ></input>
                    <label htmlFor="payment">
                      Kredity{" "}
                      <span style={{ fontSize: 12, color: "#883677" }}>
                        (aktuální zůstatek:{" "}
                        {parseFloat(props.userData.credit).toLocaleString()} Kč)
                      </span>
                    </label>
                  </div>
                ) : null}
              </fieldset>
              <button className={classes.buttonForm} type="submit">
                Uložit
              </button>
            </form>
          </div>
        </div>
      )}
      {openPaymentWall && (
        <div>
          <div
            onClick={() => {
              setOpenPaymentWall(false);
            }}
            className="backdrop"
          ></div>
          <div style={{ position: "fixed" }} className={classes.modal}>
            <h2 className="h2">Platba</h2>
            <p>
              id:{data.id} + lessons:{data.numberOfLessons} + price:
              {data.price}{" "}
            </p>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <button
                style={{
                  border: "none",
                  cursor: "pointer",
                  height: "55px",
                  width: "50%",
                  color: "white",
                  fontSize: "16px",
                  borderRadius: "4px",
                  backgroundColor: "#adb5bd",
                }}
                onClick={() => {
                  setOpenPaymentWall(false);
                }}
              >
                Zrušit
              </button>
              <button
                style={{ width: "50%", marginTop: "0px" }}
                className={classes.buttonForm}
                onClick={() => {
                  setOpenPaymentWall(false);
                  setData((data.myLesson = true));
                  setOpenNotification(true);

                  setData((data.signed += 1));
                  getLessons();
                }}
              >
                Zaplatit
              </button>
            </div>
          </div>
        </div>
      )}
      {openNotification && (
        <div
          style={{
            position: "fixed",
            top: "70px",
            right: "20px",
          }}
        >
          <NotificationGreen
            label="Úspěšně přihlášen"
            content="Alert Content"
          ></NotificationGreen>
        </div>
      )}
      {openNotificationOrange && (
        <div
          style={{
            position: "fixed",
            top: "70px",
            right: "20px",
          }}
        >
          <NotificationOrange label="Plavec byl odhlášen z lekcí"></NotificationOrange>
        </div>
      )}
      {openNotificationRed && (
        <div
          style={{
            position: "fixed",
            top: "70px",
            right: "20px",
          }}
        >
          <NotificationRed label="Něco se pokazilo, nepodařilo se uživatele přihlásit"></NotificationRed>
        </div>
      )}
    </div>
  );
};
export default Lessons;
