import classes from "./Header.module.css";
import { NavLink } from "react-router-dom";
import ProfileMan from "./ProfileMan.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import InfoChangeForm from "./InfoChangeForm";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "./Icon_logout.svg";
import axios from "axios";
import NotificationGreen from "../../UI/NoticationGreen";
import NotificationRed from "../../UI/NotificationRed";

const Header = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [openNotificationGreen, setOpenNotificationGreen] = useState();
  const [openNotificationRed, setOpenNotificationRed] = useState();
  const [changePasswordError, setChangePasswordError] = useState(false);
  const [newChangePasswordError, setNewChangePasswordError] = useState(false);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const openNotificationGreenHandler = (object) => {
    setOpenNotificationGreen(object);
    // console.log(object);
  };
  const closeNotificationGreenHandler = () => {
    setOpenNotificationGreen("");
  };
  const openNotificationRedHandler = () => {
    setOpenNotificationRed(true);
  };
  const closeNotificationRedHandler = () => {
    setOpenNotificationRed(false);
  };
  const openModalHandler = () => {
    setOpenModal(!openModal);
  };

  const logout = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      const response = await axios({
        method: "post",
        url: "https://login.mtmacademy.cz/api/v1/auth/logout",
        headers,
      });
      // console.log(response);
    } catch (errors) {
      console.log(errors);
    }
    props.deleteCookie("XSRF-TOKEN");
    props.deleteTokenHandler();
  };
  const updatePassword = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      const response = await axios.patch(
        "https://login.mtmacademy.cz/api/v1/update-my-password",
        object,
        { headers }
      );
      // console.log(response);
      openNotificationGreenHandler(response.data.message);
      setTimeout(closeNotificationGreenHandler, 3000);
      setChangePasswordError(false);
      setOpenModal(false);
    } catch (errors) {
      console.log(errors.response);
      if (errors.response.status === 400) {
        setChangePasswordError(errors.response.data.actual_password);
        setNewChangePasswordError(errors.response.data.password);
      }

      openNotificationRedHandler();
      setTimeout(closeNotificationRedHandler, 3000);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Povinný údaj";
    }
    if (!values.newPassword) {
      errors.newPassword = "Povinný údaj";
    } else if (values.newPassword === values.password) {
      errors.newPassword = "Heslo musí být odlišné než současné heslo";
    }
    if (!values.rePassword) {
      errors.rePassword = "Povinný údaj";
    } else if (values.rePassword !== values.newPassword) {
      errors.rePassword = "Musí se shodovat";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: { password: "", newPassword: "", rePassword: "" },
    validate,
    onSubmit: (values) => {
      const updatePasswordValues = {
        actual_password: values.password,
        password: values.newPassword,
        password_again: values.rePassword,
      };
      updatePassword(updatePasswordValues);

      values.password = "";
      values.newPassword = "";
      values.rePassword = "";
    },
  });
  const openMenuHandler = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className={classes.headerDiv}>
      <div className={classes.header}>
        <div className={classes.logoDiv}>
          <div className={classes.logo}>
            <Link
              style={{
                textDecoration: "none",
                color: "black",

                width: "100%",
                padding: "5px 0px",
              }}
              to="/dashboard"
            >
              Logo{" "}
            </Link>
          </div>
        </div>
        <div className={classes.links}>
          <NavLink
            className={classes.headerNavLink}
            activeClassName={classes.activeNormal}
            to="/dashboard"
          >
            Nástěnka
          </NavLink>
          <NavLink
            className={classes.headerNavLink}
            activeClassName={classes.activeNormal}
            to="/dashboard-lessons"
          >
            Přehled lekcí{" "}
          </NavLink>
          <NavLink
            className={classes.headerNavLink}
            activeClassName={classes.activeNormal}
            to="/dashboard-my-lessons"
          >
            Mé lekce
          </NavLink>{" "}
          <NavLink
            className={classes.headerNavLink}
            activeClassName={classes.activeNormal}
            to="/dashboard-my-swimmers"
          >
            Plavci
          </NavLink>
        </div>
        <div className={classes.creditDiv}>
          <p>Zůstatek</p>
          {props.userData.credit ? (
            <p
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "20px",
                margin: "0px",
              }}
            >
              {parseFloat(props.userData.credit).toLocaleString()} Kč
            </p>
          ) : (
            <div
              style={{
                color: "white",
                fontWeight: "600",
                height: "26px",
                margin: "0px",
              }}
            />
          )}
        </div>
        <div
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={() => setOpenModal(!openModal)}
        >
          <img
            style={{ height: "26px" }}
            alt="profileMan"
            src={ProfileMan}
          ></img>
        </div>
        <div style={{ cursor: "pointer", marginRight: "20px" }}>
          <img
            onClick={() => setOpenLogoutMenu(true)}
            alt="Odlhásit se"
            className={classes.logoutIcon}
            src={LogoutIcon}
          ></img>
        </div>
        <div
          id="menu"
          onClick={openMenuHandler}
          className={classes.hamburgerDiv}
        >
          <div className={classes.hamburger1}></div>
          <div className={classes.hamburger2}></div>
          <div className={classes.hamburger3}></div>
        </div>
        <CSSTransition in={openMenu} timeout={400} classNames="open">
          <div className="hiddenMenu">
            <div
              id="menu"
              onClick={openMenuHandler}
              style={{ position: "absolute", top: "21px", right: "21px" }}
              className={classes.hamburgerDiv}
            >
              <div className={classes.hamburger1}></div>
              <div className={classes.hamburger2}></div>
              <div className={classes.hamburger3}></div>
            </div>
            <div
              style={{ marginTop: "40px" }}
              className={classes.hiddenMenuLinks}
            >
              <NavLink
                onClick={openMenuHandler}
                className={classes.headerNavLink}
                activeClassName={classes.active}
                to="/dashboard"
              >
                Nástěnka
              </NavLink>
              <NavLink
                onClick={openMenuHandler}
                className={classes.headerNavLink}
                activeClassName={classes.active}
                to="/dashboard-lessons"
              >
                Přehled lekcí{" "}
              </NavLink>
              <NavLink
                onClick={openMenuHandler}
                className={classes.headerNavLink}
                activeClassName={classes.active}
                to="/dashboard-my-lessons"
              >
                Mé lekce
              </NavLink>{" "}
              <NavLink
                onClick={openMenuHandler}
                className={classes.headerNavLink}
                activeClassName={classes.active}
                to="/dashboard-my-swimmers"
              >
                Plavci
              </NavLink>
            </div>
            <div className={classes.hiddenMenuCreditDiv}>
              <p>Zůstatek</p>
              <p style={{ fontWeight: "600", fontSize: "20px", margin: "0px" }}>
                {parseInt(props.userData.credit).toLocaleString()} Kč
              </p>
            </div>
            <div
              onClick={() => {
                setOpenLogoutMenu(true);
              }}
              className={classes.logoutDiv}
              style={{ marginTop: "40px" }}
            >
              <p>Odhlásit se</p>
              <img
                alt="Odhlásit se"
                className={classes.logoutIconMenu}
                src={LogoutIcon}
              ></img>
            </div>
          </div>
        </CSSTransition>
      </div>
      {openModal && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "100vh",
            width: "100%",
            minHeight: "900px",
            zIndex: "2",
          }}
        >
          <div
            onClick={() => setOpenModal(!openModal)}
            className={classes.backdrop}
          ></div>

          <div className={classes.modal}>
            <CloseIcon
              fontSize="large"
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "grey",
                cursor: "pointer",
              }}
              onClick={() => setOpenModal(!openModal)}
            ></CloseIcon>
            <h2 className="h2">Nastavení</h2>
            <h5
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              ZMĚNA ZÁKLADNÍCH ÚDAJŮ
            </h5>
            <InfoChangeForm
              token={props.token}
              loginData={props.userData}
              greenNotification={openNotificationGreenHandler}
              userDataHandler={props.userDataHandler}
              openModalHandler={openModalHandler}
              openNotificationGreenHandler={openNotificationGreenHandler}
              closeNotificationGreenHandler={closeNotificationGreenHandler}
            ></InfoChangeForm>

            <h5
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "30px",
                marginBottom: "8px",
              }}
            >
              ZMĚNA HESLA
            </h5>

            <form
              className={classes.formDiv}
              onSubmit={formik.handleSubmit}
              style={{ marginTop: "10px" }}
            >
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Současné heslo"
                name="password"
                type="password"
                value={formik.values.password}
              ></input>
              {formik.touched.password && formik.errors.password && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {formik.errors.password}
                </div>
              )}
              {changePasswordError && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {changePasswordError}
                </div>
              )}

              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Nové heslo"
                name="newPassword"
                type="password"
                value={formik.values.newPassword}
              ></input>
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {formik.errors.newPassword}
                </div>
              )}
              {newChangePasswordError && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {newChangePasswordError}
                </div>
              )}
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Nové heslo podruhé"
                name="rePassword"
                type="password"
                value={formik.values.rePassword}
              ></input>
              {formik.touched.rePassword && formik.errors.rePassword && (
                <div style={{ marginBottom: "10px" }} className="errorInput">
                  {formik.errors.rePassword}
                </div>
              )}

              <button type="submit">Změnit heslo</button>
            </form>
          </div>
        </div>
      )}
      {openLogoutMenu && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            top: "0px",
            left: "0px",
          }}
        >
          <div
            className={classes.backdrop}
            onClick={() => setOpenLogoutMenu(false)}
          ></div>
          <div className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenLogoutMenu(false)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>
            <h2 style={{ marginRight: "40px" }} className="h2">
              Opravdu si přejete se odhlásit
            </h2>
            <div className={classes.confirmButtonDiv}>
              <button
                onClick={() => setOpenLogoutMenu(false)}
                className={classes.disagreeButton}
              >
                Zrušit
              </button>
              <Link className={classes.confirmButton} to="/">
                <button onClick={logout} className={classes.button}>
                  Odhlásit se
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {openNotificationGreen && (
        <div
          style={{
            position: "absolute",
            top: "80px",
            right: "20px",
            zIndex: "4",
          }}
        >
          <NotificationGreen label={openNotificationGreen}></NotificationGreen>
        </div>
      )}
      {openNotificationRed && (
        <div
          style={{
            position: "absolute",
            top: "80px",
            right: "20px",
            zIndex: "4",
          }}
        >
          <NotificationRed
            label="Něco se pokazilo"
            content="požadavek se nepodařilo splnit"
          ></NotificationRed>
        </div>
      )}
    </div>
  );
};
export default Header;
