import React from "react";
import Button from "../../UI/Button";
import classes from "./PaymentStatus.module.css";
import { Link } from "react-router-dom";
const PaymentStatusScreen = () => {
  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.logo}>
          <p>Logo</p>
        </div>
        <h2 className={classes.heading}>Transakce proběhla v pořádku</h2>
        <p className={classes.descript}>
          Kliknutím na tlačítko <span>pokračovat </span>se vrátite zpět do
          obchodu
        </p>
        <Link to="/dashboard-my-lessons">
          <button style={{ cursor: "pointer" }} className={classes.button}>
            Pokračovat
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentStatusScreen;
