import classes from "./Login.module.css";
import { useFormik } from "formik";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { useState } from "react";
import axios from "axios";
import { create } from "@mui/material/styles/createTransitions";

const Login = (props) => {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);

  const createCookie = (res) => {
    document.cookie = `XSRF-TOKEN=${res.data.data.access_token};path=/`;
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const login = async (values) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    try {
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/auth/sign-in",
        values,
        { headers }
      );
      createCookie(response);

      setError(false);
      props.userHandler(response.data.data);
      // console.log(response.data);
      setValid(true);
    } catch (errors) {
      console.log(errors.response);
      if (errors.response.status === 401) {
        setError(true);
      }
    }
  };

  const getUserData = async () => {
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
    //   Accept: "application/json",
    // };
    // try {
    //   const response = await axios.get(
    //     "https://login.mtmacademy.cz/api/v1/my-profile",
    //     { headers }
    //   );
    //   // console.log(response.data.data);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Povinný údaj";
    }
    if (!values.password) {
      errors.password = "Povinný údaj";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validate,
    onSubmit: (values) => {
      login(values);
      getUserData();

      // setValid(true);
      values.email = "";
      values.password = "";
    },
  });

  return (
    <div className={classes.login}>
      {valid ? <Redirect to="/dashboard"></Redirect> : null}

      <h3 className="h1">Přihlásit se</h3>
      <form onSubmit={formik.handleSubmit} className={classes.loginForm}>
        <input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Email"
          name="email"
          type="email"
        ></input>
        {/* {formik.touched.email && formik.errors.email && (
          <div className="errorInput">{formik.errors.email}</div>
        )} */}
        <input
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Heslo"
          name="password"
          type="password"
        ></input>
        {/* {formik.touched.password && formik.errors.password && (
          <div className="errorInput">{formik.errors.password}</div>
        )} */}
        {error && (
          <div style={{ marginTop: "0px" }} className="errorInput">
            Nesprávné heslo nebo e-mail
          </div>
        )}
        <Button label="Přihlásit se" type="submit"></Button>
      </form>
      <div className={classes.optionsDiv}>
        <Link className="link" to="/registration">
          <p>Vytvořit učet</p>
        </Link>
        <Link className="link" to="/reset-password">
          <p>Zapomněl jsem heslo</p>
        </Link>
      </div>
    </div>
  );
};
export default Login;
