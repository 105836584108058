import classes from "./Ui.module.css";

const NotificationGreen = (props) => {
  return (
    <div
      className={classes.NotificationGreen}
      style={{ background: "#46D35D" }}
    >
      <h3 style={{ color: "white" }} className="h3">
        {props.label}
      </h3>
      <p style={{ color: "white" }}>{props.content}</p>
    </div>
  );
};
export default NotificationGreen;
