import classes from "./Lessons.module.css";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";

const ActionButton = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.swimmer_id) {
      errors.swimmer_id = "Povinný údaj";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: { swimmer_id: "", lesson_time_id: props.object.id },
    validate,
    onSubmit: (values) => {
      // console.log(values);
      formik.resetForm();
      setOpenPopup(!openPopup);
      props.cancelLesson(values);
    },
  });

  return (
    <div>
      <button
        onClick={
          props.object.taken === false && props.object.free === true
            ? () => {
                props.buttonFunctionHandler(props.object);
              }
            : props.object.taken === true && props.activeArray !== "package"
            ? () => {
                setOpenPopup(!openPopup);
                // console.log(openPopup);
              }
            : () => {
                props.buttonFunctionHandler(props.object);
              }
        }
        className={
          props.object.free === false && props.object.taken === false
            ? classes.disabledButton
            : props.object.taken === true && props.activeArray !== "package"
            ? classes.logoutButton
            : classes.button
        }
        style={{ minWidth: "130px" }}
      >
        {props.object.free === false && props.object.taken === false
          ? "Kapacita naplněna"
          : props.object.taken === true && props.activeArray !== "package"
          ? "Odhlásit se"
          : "Přihlásit"}
      </button>
      {openPopup && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            height: "100%",
            zIndex: "2",
          }}
        >
          <div
            onClick={() => setOpenPopup(!openPopup)}
            className={classes.backdropButton}
          ></div>

          <div className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenPopup(false)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>
            <h2 style={{ marginRight: "40px" }} className="h2">
              Opravdu si přejete odhlásit se z lekce?
            </h2>
            <form onSubmit={formik.handleSubmit} id="cancelLesson">
              <select
                className={classes.select}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="swimmer_id"
                defaultValue="Plavec"
              >
                <option value="Plavec" disabled>
                  Plavec
                </option>
                {props.object.swimmers
                  // .filter((swimmer) => swimmer.category === data.category)
                  .map((swimmer) => {
                    return (
                      <option value={swimmer.id} key={swimmer.id}>
                        {swimmer.first_name}
                      </option>
                    );
                  })}
              </select>
              {formik.touched.swimmer_id && formik.errors.swimmer_id && (
                <div style={{ margin: "7px 0px" }} className="errorInput">
                  {formik.errors.swimmer_id}
                </div>
              )}
            </form>
            <div className={classes.confirmButtonDiv}>
              <button
                onClick={() => setOpenPopup(!openPopup)}
                className={classes.disagreeButton}
              >
                Neodhlásit se
              </button>
              <button
                type="submit"
                form="cancelLesson"
                // onClick={() => {
                //   console.log(props.object);
                //   const object = {
                //     lesson_time_id: props.object.id,
                //   };
                //   console.log(object);
                //   props.buttonFunctionHandlersecondary(props.object);
                //   setOpenPopup(!openPopup);
                //   props.cancelLesson(object);
                // }}
                className={classes.confirmButton}
              >
                Odhlásit se
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ActionButton;
