import classes from "./Board.module.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Loading from "../../UI/Loading";

const News = (props) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    const getNews = async () => {
      const headers = {
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      };
      try {
        setLoading(true);
        const response = await axios.get(
          "https://login.mtmacademy.cz/api/v1/news",
          { headers }
        );
        setNews(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.response);
      }
    };
    getNews();
  }, []);

  return (
    <div style={{ marginTop: "10px" }}>
      <h2 className="h2">Novinky a aktuality</h2>
      <div style={{ marginTop: "10px" }}>
        {loading && <Loading></Loading>}
        {news.map((object) => {
          return (
            <div key={object.id} className={classes.newsList}>
              <p style={{ color: "#6C757D" }} className="p">
                {object.created_at}
              </p>
              <p
                style={{
                  color: "#883677",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                {object.title}
              </p>
              <p>{object.body}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default News;
