import classes from "./Lessons.module.css";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const ActionButtonSecondary = (props) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      <button
        onClick={
          //   props.object.taken === false && props.object.free === true
          //     ? () => {
          //         props.buttonFunctionHandler(props.object);
          //         console.log("clicked");
          //       }
          //     : console.log("clicked")
          // : props.object.taken === true
          // ? () => {
          //     setOpenPopup(!openPopup);
          //     console.log(openPopup);
          //   }
          //   null
          () => {
            props.buttonFunctionHandler(props.object);
          }
        }
        className={
          props.object.free === false && props.object.taken === false
            ? classes.disabledButton
            : // : props.object.taken === true
              // ? classes.logoutButton
              classes.button
        }
        style={{ minWidth: "130px" }}
      >
        {props.object.free === false && props.object.taken === false
          ? "Kapacita naplněna"
          : //   : props.object.taken === true
            //   ? "Odhlásit se"
            "Přihlásit"}
      </button>
      {/* {openPopup && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            height: "100%",
            zIndex: "2",
          }}
        >
          <div
            onClick={() => setOpenPopup(!openPopup)}
            className={classes.backdropButton}
          ></div>
          <div className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenPopup(false)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>
            <h2 style={{ marginRight: "40px" }} className="h2">
              Opravdu si přejete odhlásit se z lekce?
            </h2>
            <div className={classes.confirmButtonDiv}>
              <button
                onClick={() => setOpenPopup(!openPopup)}
                className={classes.disagreeButton}
              >
                Neodhlásit se
              </button>
              <button
                onClick={() => {
                  console.log(props.object);
                  const object = {
                    lesson_time_id: props.object.id,
                  };
                  console.log(object);
                  props.buttonFunctionHandlersecondary(props.object);
                  setOpenPopup(!openPopup);
                  props.cancelLesson(object);
                }}
                className={classes.confirmButton}
              >
                Odhlásit se
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};
export default ActionButtonSecondary;
