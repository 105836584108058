import React from "react";
import "./index.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import LoginScreen from "./Components/LoginScreen/LoginScreen";
import Login from "./Components/LoginScreen/Login/Login";
import Registration from "./Components/LoginScreen/Registration/Registration";
import ResetPassword from "./Components/LoginScreen/ResetPassword/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import Board from "./Components/Dashboard/Board/Board";
import Lessons from "./Components/Dashboard/Lessons/Lessons";
import MyLessons from "./Components/Dashboard/MyLessons/MyLessons";
import MySwimmers from "./Components/Dashboard/MySwimmers/MySwimmers";
import { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ResetPasswordForm from "./Components/LoginScreen/ResetPassword/ResetPasswordForm";
import PaymentStatusScreen from "./Components/Dashboard/PaymentStatus/PaymentSuccessScreen";
import PaymentErrorScreen from "./Components/Dashboard/PaymentStatus/PaymentErrorScreen";
export const history = createBrowserHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0);
});
function App() {
  const [credit, setCredit] = useState(800);
  const [userData, setUserData] = useState({});
  const [swimmers, setSwimmers] = useState([]);
  const [token, setToken] = useState("");
  const [cookie, setCookie] = useState("");
  const [pools, setPools] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lessonsPackage, setLessonsPackage] = useState([]);
  const [lectors, setLectors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [months, setMonths] = useState([]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  function deleteCookie(name) {
    if (getCookie(name)) {
      document.cookie = name + "=;Max-Age=0;";
    }
  }

  const setCreditHandler = (value) => {
    setCredit(credit + value);
  };
  const setCreditPaymentHandler = (value) => {
    setCredit(credit - value);
  };

  const userHandler = (user) => {
    setToken(user.access_token);
  };
  const userDataHandler = () => {
    const getUserData = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          "https://login.mtmacademy.cz/api/v1/my-profile",
          { headers }
        );
        setUserData(response.data.data);
        // console.log(response);
      } catch (error) {
        console.log(error.response);
      }
    };
    getUserData();
  };
  const getMonthsHandler = () => {
    const getMonths = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          "https://login.mtmacademy.cz/api/v1/months",
          { headers }
        );
        setMonths(response.data.data);
        // console.log(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getMonths();
  };

  const userSwimmersHandler = () => {
    const getSwimmers = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          "https://login.mtmacademy.cz/api/v1/my-swimmers",
          { headers }
        );
        setSwimmers(response.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getSwimmers();
  };

  const getPoolsHandler = () => {
    const getPools = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          "https://login.mtmacademy.cz/api/v1/pools",
          { headers }
        );
        setPools(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getPools();
  };
  const getLessonsHandler = () => {
    const getLessons = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          `https://login.mtmacademy.cz/api/v1/lessons?pool_id=${0}&category_id=${0}&lector=${0}`,
          { headers }
        );

        setLessons(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getLessons();
  };
  const getPackageLessonsHandler = () => {
    const getPackageLessons = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          `https://login.mtmacademy.cz/api/v1/lessons-package?pool_id=${0}&category_id=${0}&lector=${0}`,
          { headers }
        );

        setLessonsPackage(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getPackageLessons();
  };
  const getLectorsHandler = () => {
    const getLectors = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          `https://login.mtmacademy.cz/api/v1/lectors`,
          { headers }
        );
        setLectors(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getLectors();
  };
  const getCategoriesHandler = () => {
    const getCategories = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
        Accept: "application/json",
      };
      try {
        const response = await axios.get(
          `https://login.mtmacademy.cz/api/v1/categories`,
          {
            headers,
          }
        );
        setCategories(response.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };
    getCategories();
  };

  useEffect(() => {
    if (token) {
      setToken(getCookie("XSRF-TOKEN"));
      userDataHandler();
      userSwimmersHandler();
      getPoolsHandler();
      getLessonsHandler();
      getLectorsHandler();
      getCategoriesHandler();
      getPackageLessonsHandler();
      getMonthsHandler();
    }
  }, [token]);

  useEffect(() => {
    setToken(getCookie("XSRF-TOKEN"));
  }, []);

  const deleteTokenHandler = () => {
    setToken("");
  };

  const addSwimmer = (object) => {
    swimmers.push(object);
  };

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <LoginScreen>
            <Login userHandler={userHandler}></Login>
          </LoginScreen>
        </Route>
        <Route path="/registration">
          <LoginScreen>
            <Registration></Registration>
          </LoginScreen>
        </Route>
        <Route path="/reset-password" exact>
          <LoginScreen>
            <ResetPassword></ResetPassword>
          </LoginScreen>
        </Route>
        <Route path="/reset-password-form">
          <LoginScreen>
            <ResetPasswordForm></ResetPasswordForm>
          </LoginScreen>
        </Route>

        <>
          <Route path="/dashboard">
            <Dashboard
              deleteCookie={deleteCookie}
              deleteTokenHandler={deleteTokenHandler}
              userDataHandler={userDataHandler}
              token={token}
              userData={userData}
            >
              <Board token={token}></Board>
            </Dashboard>
          </Route>

          <Route path="/dashboard-lessons">
            <Dashboard
              deleteCookie={deleteCookie}
              deleteTokenHandler={deleteTokenHandler}
              userDataHandler={userDataHandler}
              token={token}
              userData={userData}
            >
              <Lessons
                setCreditPaymentHandler={setCreditPaymentHandler}
                credit={credit}
                setCreditHandler={setCreditHandler}
                swimmers={swimmers}
                lessons={lessons}
                pools={pools}
                token={token}
                lectors={lectors}
                categories={categories}
                lessonsPackage={lessonsPackage}
                userData={userData}
                userDataHandler={userDataHandler}
                months={months}
              ></Lessons>
            </Dashboard>
          </Route>
          <Route path="/dashboard-my-lessons">
            <Dashboard
              deleteCookie={deleteCookie}
              deleteTokenHandler={deleteTokenHandler}
              userDataHandler={userDataHandler}
              token={token}
              userData={userData}
            >
              <MyLessons
                swimmers={swimmers}
                token={token}
                lectors={lectors}
                categories={categories}
                userDataHandler={userDataHandler}
                months={months}
              ></MyLessons>
            </Dashboard>
          </Route>
          <Route path="/dashboard-my-swimmers">
            <Dashboard
              deleteCookie={deleteCookie}
              deleteTokenHandler={deleteTokenHandler}
              userDataHandler={userDataHandler}
              token={token}
              userData={userData}
            >
              <MySwimmers
                addSwimmer={addSwimmer}
                userSwimmersHandler={userSwimmersHandler}
                token={token}
                categories={categories}
                swimmers={swimmers}
                userDataHandler={userDataHandler}
              ></MySwimmers>
            </Dashboard>
          </Route>
          <Route path="/payment-success">
            <PaymentStatusScreen />
          </Route>
          <Route path="/payment-error">
            <PaymentErrorScreen />
          </Route>
        </>
      </Switch>
    </Router>
  );
}

export default App;
