import classes from "./Board.module.css";
import info from "./CTA.svg";
import { useState, useEffect, isValidElement } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Loading from "../../UI/Loading";
import { Link } from "react-router-dom";
const ClosestLessons = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [myLessons, setMyLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const getMyLessons = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      setLoading(true);
      const response = await axios.get(
        "https://login.mtmacademy.cz/api/v1/my-lessons?page=1",
        { headers }
      );
      setMyLessons(response.data.data);

      if (response.data.data.length === 0) {
        setValid(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getMyLessons();
  }, []);

  return (
    <div className={classes.dataGrid}>
      <h3 className="h2">Mé nejbližší lekce </h3>
      {loading && <Loading></Loading>}

      <div className={classes.list}>
        {valid === false ? (
          <p>
            Žádné lekce k zobrazení.{" "}
            <Link
              style={{ color: "#883677", fontWeight: 600 }}
              to="/dashboard-lessons"
            >
              Přihlašte se na lekci
            </Link>
          </p>
        ) : null}
        {myLessons.map((object) => {
          return (
            <div key={object.id} className={classes.listItem}>
              <div>
                <p className={classes.label}>Bazen</p>
                <p className={classes.text}>{object.pool}</p>
              </div>
              <div>
                <p className={classes.label}>Datum</p>
                <p className={classes.text}>{object.date}</p>
              </div>
              <div>
                <p className={classes.label}>Čas</p>
                <p className={classes.text}>
                  {object.time_from} : {object.time_to}
                </p>
              </div>
              <div>
                <p className={classes.label}>Plavec</p>
                <p className={classes.text}>{object.swimmer}</p>
              </div>

              <img
                onClick={() => {
                  setOpenModal(!openModal);
                  setData(object);
                }}
                alt="info"
                className={classes.infoIcon}
                src={info}
              ></img>
            </div>
          );
        })}
      </div>

      {openModal && (
        <div
          style={{
            position: "fixed",

            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
          }}
        >
          <div
            className="backdrop"
            onClick={() => setOpenModal(!openModal)}
          ></div>

          <div className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenModal(!openModal)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>
            <h2 className="h2">Informace</h2>
            <h5
              style={{
                fontSize: "16px",
                marginTop: "16px",
                fontWeight: "500",
              }}
            >
              DETAIL LEKCE
            </h5>
            <p className="p" style={{ margin: "5px 0px" }}>
              Jméno: <span>{data.swimmer}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Bazén: <span>{data.pool}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Datum: <span>{data.date}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Čas:{" "}
              <span>
                {data.time_from} : {data.time_to}
              </span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Lektor: <span>{data.lector}</span>
            </p>
            <p className="p" style={{ margin: "5px 0px" }}>
              Kategorie: <span>{data.category}</span>
            </p>

            <button
              onClick={() => setOpenModal(!openModal)}
              className={classes.button}
            >
              Rozumím
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ClosestLessons;
