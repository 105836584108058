import ClosestLessons from "./ClosestLessons";
import News from "./News";
import Grid from "@mui/material/Grid";

const Board = (props) => {
  return (
    <div className="contentContainer">
      <h1 className="h1">Nástěnka</h1>
      <Grid container spacing={2}>
        <Grid style={{ width: "100%" }} item md={6} sm={12}>
          <ClosestLessons
            token={props.token}
            myLessons={props.myLessons}
          ></ClosestLessons>
        </Grid>
        <Grid item md={6} sm={12}>
          <News token={props.token} news={props.news}></News>
        </Grid>
      </Grid>
    </div>
  );
};
export default Board;
