import axios from "axios";
import Header from "./DashboardHeader/Header";
import { useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Dashboard = (props) => {
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  return (
    <div>
      {!getCookie("XSRF-TOKEN") ? (
        <Redirect to="/"></Redirect>
      ) : window.location.href.split("/")[4] === "" ? (
        <Redirect to="/dashboard" />
      ) : null}
      <Header
        deleteCookie={props.deleteCookie}
        deleteTokenHandler={props.deleteTokenHandler}
        token={props.token}
        userData={props.userData}
        userDataHandler={props.userDataHandler}
      ></Header>
      {props.children}
    </div>
  );
};
export default Dashboard;
