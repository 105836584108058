import classes from "./MyLessons.module.css";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useFormik } from "formik";
import infoIcon from "../MySwimmers/CTA.svg";

const LogoutButton = (props) => {
  const [mouseOnInfo, setMouseOnInfo] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const cancelLesson = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${props.token}`,
      Accept: "application/json",
    };
    try {
      props.cancelLessonLoadingTrueHandler();
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/cancel-lesson",
        object,
        { headers }
      );
      // console.log(response);
      props.getMyLessons();
      props.userDataHandler();
      props.cancelLessonLoadingFalseHandler();
    } catch (error) {
      console.log(error.response);
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.swimmer_id) {
      errors.swimmer_id = "Povinný údaj";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: { swimmer_id: "", lesson_time_id: props.object.id },
    validate,
    onSubmit: (values) => {
      // console.log(values);
      cancelLesson(values);
      formik.resetForm();
      props.deleteHandler(props.object);
      setOpenPopup(!openPopup);
      //  props.cancelLesson(values);
    },
  });

  return (
    <div style={{ minWidth: "none" }}>
      <button
        onClick={() => setOpenPopup(!openPopup)}
        className={classes.button}
      >
        Odhlásit se
      </button>
      {openPopup && (
        <div>
          <div
            onClick={() => setOpenPopup(!openPopup)}
            className={classes.backdrop}
          ></div>
          <div className={classes.modal}>
            <CloseIcon
              onClick={() => setOpenPopup(!openPopup)}
              fontSize="large"
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "15px",
                right: "15px",
                color: "grey",
              }}
            ></CloseIcon>

            <form id="logoutForm" onSubmit={formik.handleSubmit}>
              {props.object.swimmers.filter((object) => {
                return object.in_package === false;
              }).length === 0 ? (
                <div
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <p style={{ marginBottom: "20px" }}>
                    Litujeme, ale pro tuto lekci nelze odhlásit žádného plavce
                  </p>
                  <div
                    onMouseEnter={() => {
                      setMouseOnInfo(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOnInfo(false);
                    }}
                    style={{ margin: "0px auto", position: "relative" }}
                  >
                    <img
                      alt="option"
                      src={infoIcon}
                      style={{
                        zIndex: "2",
                        marginTop: "10px",
                      }}
                    ></img>
                    {mouseOnInfo && (
                      <div
                        style={{
                          minWidth: "150px",
                          position: "absolute",
                          bottom: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: "#883677",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "8px",
                          fontSize: "11px",
                        }}
                      >
                        <p>
                          Nelze odhlásit plavce, kteří jsou přihlášeny v rámci
                          celého balíčku
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <h2
                    style={{ marginRight: "40px", marginBottom: "10px" }}
                    className="h2"
                  >
                    Opravdu si přejete odhlásit se z lekce?
                  </h2>
                  <select
                    className={classes.select}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="swimmer_id"
                    defaultValue="Plavec"
                  >
                    <option value="Plavec" disabled>
                      Plavec
                    </option>
                    {props.object.swimmers
                      .filter((object) => {
                        return object.in_package === false;
                      })
                      // .filter((swimmer) => swimmer.category === data.category)
                      .map((swimmer) => {
                        return (
                          <option value={swimmer.id} key={swimmer.id}>
                            {swimmer.first_name}
                          </option>
                        );
                      })}
                  </select>
                  {formik.touched.swimmer_id && formik.errors.swimmer_id && (
                    <div style={{ margin: "7px 0px" }} className="errorInput">
                      {formik.errors.swimmer_id}
                    </div>
                  )}
                  <div style={{ margin: "7px 0px" }} />
                  <div className={classes.confirmButtonDiv}>
                    <button
                      onClick={() => setOpenPopup(!openPopup)}
                      className={classes.disagreeButton}
                    >
                      Neodhlásit se
                    </button>
                    <button
                      type="submit"
                      // onClick={() => {
                      //   console.log(props.object);
                      //   const object = {
                      //     lesson_time_id: props.object.id,
                      //   };
                      //   // props.deleteHandler(props.object);

                      //   // setOpenPopup(!openPopup);
                      //   // cancelLesson(object);
                      // }}
                      className={classes.confirmButton}
                    >
                      Odhlásit se
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default LogoutButton;
