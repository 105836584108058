import classes from "./ResetPassword.module.css";
import { useFormik } from "formik";
import Button from "../../UI/Button";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

const ResetPasswordForm = () => {
  let location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);

  const token = urlParams.get("token");

  const email = urlParams.get("email");

  const [success, setSuccess] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const resetPassword = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(
        "https://login.mtmacademy.cz/api/v1/password/reset",
        object,
        { headers }
      );
      // console.log(response);
      if (response.data.success === false) {
        setSuccess(false);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setSuccess(false);
      setErrorMessage(error.response.data.errors);
      console.log(error.response);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Povinný údaj";
    }
    if (!values.repassword) {
      errors.repassword = "Povinný údaj";
    } else if (values.repassword !== values.password) {
      errors.repassword = "Hesla se neshodují";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: { password: "", repassword: "" },
    validate,
    onSubmit: (values) => {
      const object = {
        email: email,
        token: token,
        password: values.password,
        password_confirmation: values.password,
      };
      resetPassword(object);
      // console.log(object);
      // console.log(values);
      values.password = "";
      values.repassword = "";
    },
  });
  return (
    <div className={classes.resetPassword}>
      {success === true ? (
        <>
          <h2 className="h2">Heslo bylo úspěšně změnilo</h2>
          <Link className="link" to="/">
            <Button label="Přihlásit se"></Button>
          </Link>
        </>
      ) : (
        <>
          <h2 className="h1">Změna hesla pro:</h2>
          <h3
            className="h3"
            style={{
              margin: "0px",
              marginTop: 2,
              marginBottom: -5,
              textDecoration: "underline 1px black",
              textUnderlineOffset: 4,
              fontSize: 17,
              color: "#883677",
            }}
          >
            {email}
          </h3>
          <p style={{ marginTop: "10px" }} className="p">
            Po vyplnění formuláře vám obnovíme heslo, na které se posléze můžete
            přihlásit
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.resetPasswordForm}
          >
            <input
              value={formik.values.password}
              type="password"
              placeholder="Nové heslo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
            ></input>
            {formik.touched.password && formik.errors.password && (
              <div className="errorInput">{formik.errors.password}</div>
            )}
            <input
              value={formik.values.repassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="repassword"
              type="password"
              placeholder="Nové heslo znovu"
            ></input>
            {formik.touched.repassword && formik.errors.repassword && (
              <div className="errorInput">{formik.errors.repassword}</div>
            )}
            {success === false && <p>Něco se pokazilo</p>}
            {success === false && <p>{errorMessage}</p>}

            <Button label="Resetovat heslo" type="submit"></Button>
            <div className={classes.optionsDiv}>
              <Link className="link" to="/">
                <p>Přihlásit se</p>
              </Link>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
export default ResetPasswordForm;
