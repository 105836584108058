const Button = (props) => {
  return (
    <button
      type="submit"
      style={{
        width: "100%",
        height: "48px",
        color: "white",
        fontSize: "16px",
        fontWeight: "700",
        background: "#883677",
        border: "none",
        borderRadius: "4px",
        marginTop: "10px",
        cursor: "pointer",
      }}
    >
      {props.label}
    </button>
  );
};
export default Button;
