import classes from "./Header.module.css";
import { useFormik } from "formik";
import axios from "axios";
import { useState } from "react";

const InfoChangeForm = (props) => {
  const [profileInfo, setProfileInfo] = useState(props.loginData);
  const [emailError, setEmailError] = useState(false);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const getUserData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      const response = await axios.get(
        "https://login.mtmacademy.cz/api/v1/my-profile",
        { headers }
      );
      setProfileInfo(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const updateProfile = async (object) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${getCookie("XSRF-TOKEN")}`,
      Accept: "application/json",
    };
    try {
      const response = await axios.patch(
        "https://login.mtmacademy.cz/api/v1/update-my-profile",
        object,
        { headers }
      );
      // console.log(response);
      getUserData();
      props.userDataHandler();
      props.openModalHandler();
      props.openNotificationGreenHandler(response.data.message);
      setTimeout(props.closeNotificationGreenHandler(), 3000);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setEmailError(error.response.data.email);
      } else {
        console.log(error.response);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Povinný údaj";
    }
    if (!values.name) {
      errors.name = "Povinný údaj";
    }
    if (!values.surname) {
      errors.surname = "Povinný údaj";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: { email: "", name: "", surname: "" },
    validate,
    onSubmit: (values) => {
      // console.log(values);
      const infoToUpdate = {
        email: values.email,
        newsletter: true,
        first_name: values.name,
        last_name: values.surname,
      };
      updateProfile(infoToUpdate);

      values.email = "";
      values.name = "";
      values.surname = "";
    },
  });

  return (
    <form className={classes.formDiv} onSubmit={formik.handleSubmit}>
      <input
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="email"
        placeholder={profileInfo.email}
      ></input>
      {formik.touched.email && formik.errors.email && (
        <div
          style={{ marginTop: "6", marginBottom: "10px" }}
          className="errorInput"
        >
          {formik.errors.email}
        </div>
      )}
      {emailError && (
        <div
          style={{ margin: "6", marginBottom: "10px" }}
          className="errorInput"
        >
          {emailError}
        </div>
      )}
      <input
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="name"
        type="name"
        placeholder={profileInfo.first_name}
      ></input>
      {formik.touched.name && formik.errors.name && (
        <div style={{ marginBottom: "10px" }} className="errorInput">
          {formik.errors.name}
        </div>
      )}
      <input
        value={formik.values.surname}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="surname"
        placeholder={profileInfo.last_name}
      ></input>
      {formik.touched.surname && formik.errors.surname && (
        <div
          style={{ marginTop: "6", marginBottom: "10px" }}
          className="errorInput"
        >
          {formik.errors.surname}
        </div>
      )}
      <button type="submit">Aktualizovat údaje</button>
    </form>
  );
};
export default InfoChangeForm;
