import classes from "./Ui.module.css";

const NotificationRed = (props) => {
  return (
    <div
      className={classes.NotificationOrange}
      style={{ background: "#FC3934" }}
    >
      <h3 style={{ color: "white" }} className="h3">
        {props.label}
      </h3>
      <p style={{ color: "white" }}>{props.content}</p>
    </div>
  );
};
export default NotificationRed;
